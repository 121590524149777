import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ICheckbox } from 'src/app/interfaces/checkbox.interface';
import { AnswerOption } from 'src/app/models/answer-option.model';
import { Question } from 'src/app/models/question.model';
import { SurveyService } from 'src/app/services/survey.service';
import { QDefaultComponent } from '~components/questions/q-default/q-default.component';
import { QRangeComponent } from '~components/questions/q-range/q-range.component';
import { QSelectMultipleComponent } from '~components/questions/q-select-multiple/q-select-multiple.component';
import { QSelectSingleComponent } from '~components/questions/q-select-single/q-select-single.component';

@Component({
  selector: 'survey-question',
  templateUrl: './survey-question.component.html',
  styleUrls: ['./survey-question.component.scss']
})
export class SurveyQuestionComponent implements OnInit {
  @Input() question: Question;

  questionForm: FormGroup;

  constructor(private surveyService: SurveyService) {}

  ngOnInit(): void {
    const split = this.question?.xtype?.split('-');
    const inputSpecification = split[split.length - 1];
    switch (inputSpecification) {
      case 'multiple':
        this.questionForm = new FormGroup({
          answer: QSelectMultipleComponent.setupForm(this.question, this.getOptions(), 1, 100)
        });
        break;
      case 'single':
        this.questionForm = new FormGroup({
          answer: QSelectSingleComponent.setupForm(this.question, this.getOptions())
        });
        break;
      case 'range':
        this.questionForm = new FormGroup({
          answer: QRangeComponent.setupForm(this.question, 1, 100)
        });
        break;
      default:
        this.questionForm = new FormGroup({
          answer: QDefaultComponent.setupForm(this.question)
        });
        break;
    }
  }

  get inputType() {
    const split = this.question?.xtype?.split('-');
    return split.length > 1 ? split[split.length - 1] : 'text';
  }

  get answerForm(): FormGroup {
    return this.questionForm.get('answer') as FormGroup;
  }

  get option(): AnswerOption {
    return this.surveyService.getAnswerOptionsForQuestion(this.question.id);
  }

  getOptions(): ICheckbox[] {
    return this.surveyService.getOptionsAsCheckboxes(this.question.answerOptions);
  }

  public isQuestionAnswered(): boolean {
    return this.questionForm.valid;
  }
}
