<form [formGroup]="answerForm">
  <div class="form-group row">
    <label class="col-12 col-form-label">{{ question.text | customTranslate }}</label>
    <div class="col-sm-12">
      <div class="form-check d-inline-block mr-3" *ngFor="let option of options; let i = index">
        <input
          class="form-check-input"
          type="radio"
          name="value"
          id="{{ question.id }}-option-{{ i }}"
          [value]="option.value"
          (change)="onOptionSelectedChanged(option, i)"
          [checked]="answerForm.get('value').value === option.value"
        />
        <label class="form-check-label" for="{{ question.id }}-option-{{ i }}">
          {{ option.label | customTranslate }}
        </label>
      </div>
    </div>
  </div>
</form>
