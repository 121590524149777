import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Survey } from '../models/survey.model';
import { SurveyService } from '../services/survey.service';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SurveyResolver implements Resolve<Survey> {
  constructor(private surveyService: SurveyService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Survey> | Promise<Survey> | Survey {
    const token = route.paramMap.get('token');
    return this.surveyService.loadSurvey(token).pipe(
      catchError(error => {
        let errorMessage;
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          if ([403, 404].includes(error.status)) {
            this.router.navigate([
              'surveycompleted',
              error.status === 403 ? 'expired' : 'completed'
            ]);
          } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
        }
        return throwError(errorMessage);
      })
    );
  }
}
