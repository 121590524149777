<div class="row">
  <div class="col-12">
    <div class="progress">
      <div
        *ngIf="currentStep > 0"
        class="progress-bar progress-bar-striped bg-secondary"
        [style.width]="progress"
      >
        {{ progress }}
      </div>
    </div>
  </div>
</div>
