import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private readonly supportedLanguages = ['en', 'nl', 'de'];
  private readonly supportedCultures = ['en-CA', 'en-GB', 'nl', 'de'];
  private readonly fallbackLanguage = 'en';

  constructor(private translateService: TranslateService) {}

  initI18n(towLetterLanguageCode: string) {
    this.translateService.addLangs(this.supportedLanguages);
    // const browserLang = this.translateService.getBrowserLang();
    //
    // const defaultLang = this.isLanguageSupported(browserLang) ? browserLang : this.fallbackLanguage;
    // this.translateService.setDefaultLang(defaultLang);
    //
    // // Get preferred language from localstorage
    // const preferredLang = this.getPreferredLanguage();
    // if (!preferredLang) this.setPreferredLanguage(defaultLang);

    // Fallback default is default language
    this.changeLanguage(towLetterLanguageCode);
  }

  private isLanguageSupported(lang: string) {
    return lang.match(`/${this.supportedLanguages.join('|')}/`);
  }

  private isCultureSupported(culture: string) {
    return this.supportedCultures.indexOf(culture) > -1;
  }

  changeLanguage(lang: string) {
    this.translateService.use(lang);
    const locale = `${lang}-${this.culture}`;
    if (this.isCultureSupported(locale)) {
      moment.locale(locale);
    } else if (this.isCultureSupported(this.culture)) {
      moment.locale(this.culture);
    } else {
      moment.locale(lang);
    }
  }

  setPreferredLanguage(lang: string) {
    localStorage.setItem('preferredLang', lang);
  }

  getPreferredLanguage() {
    return localStorage.getItem('preferredLang') || undefined;
  }

  private get culture() {
    const browserLocale = this.translateService.getBrowserCultureLang();
    const culture = browserLocale.split('-')[1] || browserLocale;
    return culture;
  }
}
