import { QuestionGroup } from './question-group.model';

export class SurveyTemplate {
  title: string;
  text: string;
  groups: QuestionGroup[];

  constructor(json: any) {
    this.title = json.title;
    this.text = json.text;
    this.groups = json.groups?.map(x => new QuestionGroup(x));
  }
}
