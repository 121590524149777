import { ValidatorFn, FormArray } from '@angular/forms';

export class ArrayValidators {
  /**
   * Validator function to check the maximum amount of controls in a form array.
   * @description useful for dynamic form arrays where controls can be added or removed.
   * @static
   * @param {number} max
   * @returns {ValidatorFn}
   */
  public static maxLength(max: number): ValidatorFn {
    const validator: ValidatorFn = (control: any) => {
      if (!(control instanceof FormArray)) return;
      return control.length > max ? { maxLength: true } : null;
    };
    return validator;
  }

  /**
   * Validator function to check the minimum amount of controls in a form array.
   * @description useful for dynamic form arrays where controls can be added or removed.
   * @static
   * @param {number} min
   * @returns {ValidatorFn}
   */
  public static minLength(min: number): ValidatorFn {
    const validator: ValidatorFn = (control: any) => {
      if (!(control instanceof FormArray)) return;
      return control.length < min ? { minLength: true } : null;
    };
    return validator;
  }

  /**
   * Validator function to check the minimum amount of checked items in a form array.
   *
   * @static
   * @param {number} min
   * @returns {ValidatorFn}
   */
  public static minSelected(min: number): ValidatorFn {
    const validator: ValidatorFn = (formArray: FormArray) => {
      if (!min) return null;
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map((control: any) => {
          if (!control?.value) return false;
          return typeof control.value === 'boolean' ? control.value : control.value.selected;
        })
        // total up the number of checked checkboxes
        .reduce((prev, next) => (next ? prev + next : prev), 0);
      // if the total is less than the minimum, return the error message
      return totalSelected < min ? { minSelected: true } : null;
    };
    return validator;
  }

  /**
   * Validator function to check the maximum amount of checked items in a form array.
   *
   * @static
   * @param {number} max
   * @returns {ValidatorFn}
   */
  public static maxSelected(max: number): ValidatorFn {
    const validator: ValidatorFn = (formArray: FormArray) => {
      if (!max) return null;
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map((control: any) => {
          if (!control?.value) return false;
          return typeof control.value === 'boolean' ? control.value : control.value.selected;
        })
        // total up the number of checked checkboxes
        .reduce((prev, next) => (next ? prev + next : prev), 0);
      // if the total is greater than the maximum, return the error message
      return totalSelected > max ? { maxSelected: true } : null;
    };
    return validator;
  }
}
