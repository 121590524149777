import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { QuestionGroup } from 'src/app/models/question-group.model';
import { SurveyQuestionComponent } from '~components/survey-question/survey-question.component';

@Component({
  selector: 'survey-question-group',
  templateUrl: './survey-question-group.component.html',
  styleUrls: ['./survey-question-group.component.scss']
})
export class SurveyQuestionGroupComponent implements OnInit {
  @Input() questionGroup: QuestionGroup;
  @ViewChildren(SurveyQuestionComponent) questions: QueryList<SurveyQuestionComponent>;

  constructor() {}

  ngOnInit(): void {}

  public isQuestionGroupValid(): boolean {
    return this.questions.toArray().every(question => question.isQuestionAnswered());
  }
}
