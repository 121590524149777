import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SurveyService } from '../services/survey.service';

@Pipe({
  name: 'customTranslate'
})
export class CustomTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService, private surveyService: SurveyService) {}
  transform(value: any, ...args: any[]): any {
    const text = this.surveyService.getTranslationForKey(value);
    return text;
  }
}
