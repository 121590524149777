import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEnCa from '@angular/common/locales/en-CA';
import localeEnDe from '@angular/common/locales/en-DE';
import localeEnNl from '@angular/common/locales/en-NL';
import localeFrCa from '@angular/common/locales/fr-CA';
import localeNl from '@angular/common/locales/nl';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { ArchwizardModule } from 'angular-archwizard';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { QDefaultComponent } from './components/questions/q-default/q-default.component';
import { QRangeComponent } from './components/questions/q-range/q-range.component';
import { QSelectMultipleComponent } from './components/questions/q-select-multiple/q-select-multiple.component';
import { QSelectSingleComponent } from './components/questions/q-select-single/q-select-single.component';
import { SurveyQuestionGroupComponent } from './components/survey-question-group/survey-question-group.component';
import { SurveyQuestionComponent } from './components/survey-question/survey-question.component';
import { SurveyComponent } from './components/survey/survey.component';
import { CustomTranslatePipe } from './pipes/custom-translate.pipe';
import { QBaseComponent } from './components/questions/q-base/q-base.component';
import { QTextareaComponent } from './components/questions/q-textarea/q-textarea.component';
import { SurveyProgressComponent } from './components/survey-progress/survey-progress.component';
import { SurveyCompletedComponent } from './components/survey-completed/survey-completed.component';

// Factory method to load app config from json file.
export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

registerLocaleData(localeNl, 'nl');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeFrCa, 'fr-CA');
registerLocaleData(localeEnNl, 'en-NL');
registerLocaleData(localeEnCa, 'en-CA');
registerLocaleData(localeEnDe, 'en-DE');

@NgModule({
  declarations: [
    AppComponent,
    SurveyComponent,
    QDefaultComponent,
    QRangeComponent,
    CustomTranslatePipe,
    SurveyQuestionComponent,
    QSelectSingleComponent,
    QSelectMultipleComponent,
    SurveyQuestionGroupComponent,
    QBaseComponent,
    QTextareaComponent,
    SurveyProgressComponent,
    SurveyCompletedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgBootstrapFormValidationModule,
    TranslateModule.forRoot(),
    ArchwizardModule
  ],
  providers: [
    AppConfig,
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig], multi: true },
    {
      provide: ErrorHandler,
      useClass: ErrorHandler
    },
    CustomTranslatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
