import { Component, OnInit, Input } from '@angular/core';
import { Question } from 'src/app/models/question.model';
import { IQuestionComponent } from 'src/app/interfaces/question-component.interface';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'q-base',
  template: `
    NO UI HERE
  `
})
export class QBaseComponent implements OnInit, IQuestionComponent {
  constructor() {}

  @Input() question: Question;
  @Input() answerForm: FormGroup;

  public static setupForm(question: Question, ...args: any): FormGroup {
    return new FormGroup({ value: new FormControl(question.answer, Validators.required) });
  }

  ngOnInit(): void {}

  onValueChanged(event: any) {
    this.question.answer = event.target.value;
  }
}
