import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { AppConfig } from '../app.config';
import { API_ENDPOINT_SURVEYS } from '../constants/app.constants';
import { ICheckbox } from '../interfaces/checkbox.interface';
import { AnswerOptionValue } from '../models/answer-option-value.model';
import { AnswerOption } from '../models/answer-option.model';
import { Survey } from '../models/survey.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  private cachedSurvey$: Observable<Survey>;
  private _currentSurvey: Survey;
  private _currentToken: string;
  private _loading: boolean;

  public get loading() {
    return this._loading;
  }

  public get currentToken() {
    return this._currentToken;
  }

  public set currentToken(value: string) {
    this._currentToken = value;
  }

  public get currentSurvey() {
    return this._currentSurvey;
  }

  public set currentSurvey(value: Survey) {
    this._currentSurvey = value;
  }

  constructor(private http: HttpClient, private translationService: TranslateService) {}

  loadSurvey(token: string): Observable<any> {
    if (this.cachedSurvey$) return this.cachedSurvey$;
    this._loading = true;
    this.cachedSurvey$ = this.getSurveyWithAccessToken(token).pipe(
      map(x => new Survey(x)),
      tap(survey => {
        this.currentToken = token;
        this.currentSurvey = survey;
        this._loading = false;
      }),
      shareReplay(1),
      catchError(error => {
        this._loading = false;
        console.log(`Error retrieving survey with token "${this.currentToken}".`, error);
        throw error;
      })
    );
    return this.cachedSurvey$;
  }

  saveSurvey(survey: Survey, completed: boolean): Observable<any> {
    return this.http
      .put(
        // tslint:disable-next-line:max-line-length
        `${AppConfig.settings.api.baseUrl}/${API_ENDPOINT_SURVEYS}/UserSurveyContents/AccessToken/${this.currentToken}?completed=${completed}`,
        survey
      )
      .pipe(
        catchError(error => {
          console.log(`Error updating survey with token "${this.currentToken}".`, error);
          throw error;
        })
      );
  }

  getTranslationForKey(key: string): string {
    try {
      if (!key.startsWith('#')) return key;
      const resourceKey = this.getResourceKey(key);
      const sectionKey = this.getSectionKey(key);
      const translationResource = this.currentSurvey[sectionKey]?.find(x => x.id === resourceKey);
      const translatedValue = translationResource?.languages[
        this.translationService.currentLang
      ] as string;
      return translatedValue ? translatedValue : '';
    } catch (error) {
      console.log(`Warning: Missing translation for ${key}`, error);
      return '';
    }
  }

  getAnswerOptionsForQuestion(key: string): AnswerOption {
    try {
      const answerOptionsId = this.getResourceKey(key);
      return this.currentSurvey?.answerOptions?.find(x => x.id === answerOptionsId);
    } catch (error) {
      console.log(`Warning: Missing options for ${key}`, error);
      return;
    }
  }

  getOptionsAsCheckboxes(optionsId: string): ICheckbox[] {
    const option = this.getAnswerOptionsForQuestion(optionsId);
    const checkboxes: ICheckbox[] = option?.values.map((x: AnswerOptionValue) => {
      return { label: x.text, value: x.id, selected: false };
    });
    return checkboxes;
  }

  private getSurveyWithAccessToken(token: string) {
    return this.http.get(
      `${AppConfig.settings.api.baseUrl}/${API_ENDPOINT_SURVEYS}/UserSurveyContents/AccessToken/${token}`
    );
  }

  private getResourceKey(key: string): string {
    const splitKey = key.split('/');
    const resourceKey = splitKey[splitKey.length - 1];
    return resourceKey;
  }

  private getSectionKey(key: string): string {
    const splitKey = key.split('/');
    const sectionKey = splitKey[1];
    return sectionKey;
  }
}
