<form [formGroup]="answerForm">
  <div class="form-group row">
    <label class="col-12 col-form-label">{{ question.text | customTranslate }}</label>
    <div class="col-12">
      <input
        type="{{ question.xtype }}"
        class="form-control"
        [id]="question.id"
        formControlName="value"
        (change)="onValueChanged($event)"
      />
    </div>
  </div>
</form>
