import { Question } from './question.model';

export class QuestionGroup {
  id: string;
  title: string;
  text: string;
  groups: QuestionGroup[];
  questions: Question[];

  constructor(json: any) {
    this.id = json.id;
    this.title = json.title;
    this.text = json.text;
    this.questions = json.questions?.map(x => new Question(x));
    this.groups = json.groups?.map(x => new QuestionGroup(x));
  }
}
