import { Component, Input, OnInit } from '@angular/core';
import { QBaseComponent } from '../q-base/q-base.component';

@Component({
  selector: 'q-default',
  templateUrl: './q-default.component.html',
  styleUrls: ['./q-default.component.scss']
})
export class QDefaultComponent extends QBaseComponent implements OnInit {
  constructor() {
    super();
  }
  @Input() min: number;
  @Input() max: number;

  ngOnInit() {}
}
