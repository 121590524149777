import { Component, OnInit } from '@angular/core';
import { QBaseComponent } from '../q-base/q-base.component';

@Component({
  selector: 'app-q-textarea',
  templateUrl: './q-textarea.component.html',
  styleUrls: ['./q-textarea.component.scss']
})
export class QTextareaComponent extends QBaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
