<div class="question-group mt-2 p-lg-3">
  <div class="row">
    <div class="col-12 text-md-center text-sm-justify">
      <h5>{{ questionGroup.title | customTranslate }}</h5>
      <p style="white-space: pre-line" [innerHTML]="questionGroup.text | customTranslate"></p>
    </div>
  </div>
  <div class="row">
    <div class="col-12" *ngFor="let question of questionGroup.questions">
      <survey-question [question]="question"></survey-question>
    </div>
  </div>
</div>
