<div class="container survey-container" *ngIf="survey">
  <div class="container-content">
    <div class="row">
      <div class="col-12 p-3">
        <h1 class="text-center text-light display-4">{{ template.title | customTranslate }}</h1>
        <p class="lead text-center text-light">{{ template.text | customTranslate }}</p>
      </div>
    </div>
    <div class="row template-container" *ngFor="let templateGroup of template.groups">
      <div class="col-12 p-sm-3 p-lg-4">
        <p class="lead">{{ templateGroup.title | customTranslate }}</p>
        <p
          class=""
          style="white-space: pre-line"
          [innerHTML]="templateGroup.text | customTranslate"
        ></p>
      </div>
      <div class="col-12 wizard-container p-sm-3 p-lg-4">
        <aw-wizard [awNavigationMode] navigateForward="allow" navigateBackward="allow">
          <aw-wizard-step
            *ngFor="let questionGroup of templateGroup.groups; let i = index; let last = last"
            class="survey-step"
            [stepTitle]="questionGroup.title | customTranslate"
            [stepId]="questionGroup.id"
          >
            <survey-progress
              [currentStep]="getCurrentStep()"
              [totalSteps]="getTotalSteps()"
            ></survey-progress>
            <survey-question-group [questionGroup]="questionGroup"> </survey-question-group>
            <div class="wizard-nav-buttons">
              <button class="btn btn-secondary" awPreviousStep>Previous</button>
              <button
                class="btn btn-secondary"
                *ngIf="!last"
                [disabled]="!isQuestionGroupValid(i)"
                (click)="updateSurvey()"
                awNextStep
              >
                Next
              </button>
              <button
                class="btn btn-success"
                *ngIf="last"
                [disabled]="!isQuestionGroupValid(i)"
                (click)="submitSurvey()"
                routerLink="/surveycompleted/completed"
              >
                Submit
              </button>
            </div>
          </aw-wizard-step>
        </aw-wizard>
      </div>
    </div>
  </div>
</div>
