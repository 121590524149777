export class Question {
  id: string;
  text: string;
  sequence: number;
  answer: any;
  xtype: string;
  value: any;
  answerOptions: string;

  constructor(json: any) {
    this.id = json.id;
    this.text = json.text;
    this.answer = json.answer;
    this.xtype = json.xtype;
    this.sequence = json.sequence;
    this.answerOptions = json.answerOptions;
  }
}
