import { AnswerOptionValue } from './answer-option-value.model';

export class AnswerOption {
  id: string;
  values: AnswerOptionValue[];

  constructor(json: any) {
    this.id = json.id;
    this.values = json.values;
  }
}
