import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ICheckbox } from 'src/app/interfaces/checkbox.interface';
import { QBaseComponent } from '../q-base/q-base.component';

@Component({
  selector: 'q-select-single',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './q-select-single.component.html',
  styleUrls: ['./q-select-single.component.scss']
})
export class QSelectSingleComponent extends QBaseComponent implements OnInit {
  constructor() {
    super();
  }

  @Input() options: ICheckbox[];

  ngOnInit(): void {}

  onOptionSelectedChanged(option: ICheckbox, index: number) {
    this.question.answer = this.options[index].value as any;
    if (this.answerForm.controls['value'].value !== option.value) {
      this.answerForm.controls['value'].patchValue(option.value);
    }
  }
}
