import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ICheckbox } from 'src/app/interfaces/checkbox.interface';
import { ArrayValidators } from 'src/app/validators/array-validators';
import { QBaseComponent } from '../q-base/q-base.component';
import { Question } from 'src/app/models/question.model';

@Component({
  selector: 'q-select-multiple',
  templateUrl: './q-select-multiple.component.html',
  styleUrls: ['./q-select-multiple.component.scss']
})
export class QSelectMultipleComponent extends QBaseComponent implements OnInit {
  constructor() {
    super();
  }

  @Input() options: ICheckbox[] = [];
  @Input() min: number;
  @Input() max: number;

  public static setupForm(
    question: Question,
    options: ICheckbox[],
    min: number,
    max: number
  ): FormGroup {
    return new FormGroup({
      options: new FormArray(
        options.map(cb => {
          return new FormControl({ value: cb.selected, disabled: false });
        }),
        [Validators.required, ArrayValidators.minSelected(min), ArrayValidators.maxSelected(max)]
      )
    });
  }

  get optionsArray(): FormArray {
    return this.answerForm?.get('options') as FormArray;
  }

  ngOnInit(): void {}

  onValueChanged(event: any) {
    this.question.answer = this.optionsArray.value
      .map((val, index) => {
        if (val) return this.options[index].value;
      })
      .filter(checked => checked);
  }
}
