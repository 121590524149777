import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SurveyComponent } from '~components/survey/survey.component';
import { SurveyResolver } from './resolvers/survey.resolver';
import { SurveyCompletedComponent } from '~components/survey-completed/survey-completed.component';

const routes: Routes = [
  {
    path: 'survey/:token',
    component: SurveyComponent,
    resolve: { survey: SurveyResolver }
  },
  {
    path: 'surveycompleted/:reason',
    component: SurveyCompletedComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
