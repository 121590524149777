import { Component } from '@angular/core';
import { SurveyService } from './services/survey.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SurveysApp';

  constructor(private surveyService: SurveyService) {}

  get loading() {
    return this.surveyService.loading;
  }
}
