<div class="container survey-container">
  <div class="container-content">
    <div class="row" *ngIf="template">
      <div class="col-12 p-3">
        <h1 class="text-center text-light display-4">{{ template.title | customTranslate }}</h1>
        <p class="lead text-center text-light">{{ template.text | customTranslate }}</p>
      </div>
    </div>
    <div class="row template-container">
      <div class="col-12 p-sm-3 pt-lg-4 pr-lg-4 pb-lg-0 pl-lg-4">
        <p class="lead text-center">{{ translatedFinishedSurveyTitle }}</p>
      </div>
      <div class="col-12 wizard-container p-sm-3 pt-lg-0 pr-lg-4 pb-lg-4 pl-lg-4">
        <p class="text-center">{{ translatedFinishedSurveyText }}</p>
      </div>
    </div>
  </div>
</div>
