<form [formGroup]="answerForm">
  <div class="form-group row">
    <label class="col-12 col-form-label">{{ question.text | customTranslate }}</label>
    <div class="col-12">
      <textarea
        class="form-control"
        [id]="question.id"
        formControlName="value"
        (change)="onValueChanged($event)"
        rows="3"
      >
      </textarea>
    </div>
  </div>
</form>
