import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { LanguageResource } from 'src/app/interfaces/language-resource.interface';
import { Survey } from 'src/app/models/survey.model';
import { SurveyTemplate } from 'src/app/models/template.model';
import { SurveyService } from 'src/app/services/survey.service';
import { SurveyQuestionGroupComponent } from '~components/survey-question-group/survey-question-group.component';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() survey: Survey;

  @ViewChildren(SurveyQuestionGroupComponent) questionGroupComponents: QueryList<
    SurveyQuestionGroupComponent
  >;
  @ViewChild(WizardComponent)
  public wizard: WizardComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private surveyService: SurveyService,
    private _changeDetectionRef: ChangeDetectorRef,
    private languageService: LanguageService
  ) {}

  get template(): SurveyTemplate {
    return this.survey ? this.survey.templateDefinition : undefined;
  }

  get translations(): LanguageResource[] {
    return this.survey ? this.survey.languageResources : undefined;
  }

  get humanReadableAnswers() {
    return this.template.groups.map(group => {
      return group.groups.map(subGrp =>
        subGrp.questions.map(x => {
          return { id: x.id, answer: x.answer };
        })
      );
    });
  }

  get currentStepIndex(): number {
    return this.questionGroupComponents?.toArray().reduce((accumulator, currentValue) => {
      return accumulator + (currentValue.isQuestionGroupValid() ? 1 : 0);
    }, 0);
  }

  getCurrentStep(): number {
    return this.template.groups.reduce((totalGroup, currentGroup) => {
      return (totalGroup += currentGroup.groups.reduce((totalSubgroup, currentSubgroup) => {
        return (totalSubgroup += currentSubgroup.questions.reduce(
          (questionsTotal, currentQuestion) => {
            return (questionsTotal += currentQuestion.answer ? 1 : 0);
          },
          0
        ));
      }, 0));
    }, 0);
  }

  getTotalSteps(): number {
    return this.template.groups.reduce((totalGroup, currentGroup) => {
      return (totalGroup += currentGroup.groups.reduce((totalSubgroup, currentSubgroup) => {
        return (totalSubgroup += currentSubgroup.questions.length);
      }, 0));
    }, 0);
  }

  ngOnInit() {
    const langCode = this.route.snapshot.queryParamMap.get('languagecode');
    this.languageService.initI18n(langCode);

    if (!this.survey) this.survey = this.route.snapshot.data['survey'];
  }

  ngAfterViewInit(): void {
    // Force another change detection in order to fix the ngFor error
    this._changeDetectionRef.detectChanges();
    this.checkSurveyProgress();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.survey) this.surveyService.currentSurvey = changes.survey.currentValue;
  }

  checkSurveyProgress() {
    this.wizard.goToStep(this.currentStepIndex);
  }

  isQuestionGroupValid(index: number) {
    return this.questionGroupComponents?.toArray()[index]?.isQuestionGroupValid();
  }

  updateSurvey() {
    this.surveyService.saveSurvey(this.survey, false).subscribe(
      result => {
        console.log('TCL: SurveyComponent -> updateSurvey -> result', result);
      },
      error => {
        console.log('TCL: SurveyComponent -> updateSurvey -> error', error);
      }
    );
  }

  submitSurvey() {
    this.surveyService.saveSurvey(this.survey, true).subscribe(
      result => {
        console.log('TCL: SurveyComponent -> updateSurvey -> result', result);
      },
      error => {
        console.log('TCL: SurveyComponent -> updateSurvey -> error', error);
      }
    );
  }
}
