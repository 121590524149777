<form [formGroup]="questionForm">
  <div [ngSwitch]="question.xtype">
    <div *ngSwitchCase="'input-range'">
      <q-range
        [question]="question"
        [min]="1"
        [max]="100"
        formGroupName="answer"
        [answerForm]="answerForm"
      ></q-range>
    </div>
    <div *ngSwitchCase="'select-single'">
      <q-select-single
        formGroupName="answer"
        [answerForm]="answerForm"
        [question]="question"
        [options]="getOptions()"
      ></q-select-single>
    </div>
    <div *ngSwitchCase="'select-multiple'">
      <q-select-multiple
        formGroupName="answer"
        [answerForm]="answerForm"
        [question]="question"
        [min]="1"
        [options]="getOptions()"
      ></q-select-multiple>
    </div>
    <div *ngSwitchDefault>
      <q-default [question]="question" formGroupName="answer" [answerForm]="answerForm"></q-default>
    </div>
  </div>
</form>
