<form [formGroup]="answerForm">
  <div class="form-group row">
    <label class="col-12 col-form-label">{{ question.text | customTranslate }}</label>
    <div class="col-sm-12">
      <div
        class="form-check d-inline-block mr-1"
        *ngFor="let option of optionsArray?.controls; let i = index"
      >
        <div class="pretty p-icon p-jelly">
          <input
            id="{{ question.id }}-option-{{ i }}"
            type="checkbox"
            name="{{ question.id }}-checkbox"
            [formControl]="option"
            (change)="onValueChanged($event)"
          />
          <div class="state" [ngClass]="option.invalid ? 'p-danger' : 'p-primary'">
            <i class="icon mdi mdi-check"></i>
            <label>{{ options[i].label | customTranslate }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
