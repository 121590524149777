import { Component, Input, OnInit } from '@angular/core';
import { QBaseComponent } from '../q-base/q-base.component';

@Component({
  selector: 'q-range',
  templateUrl: './q-range.component.html',
  styleUrls: ['./q-range.component.scss']
})
export class QRangeComponent extends QBaseComponent implements OnInit {
  constructor() {
    super();
  }
  @Input() min: number;
  @Input() max: number;

  ngOnInit() {}
}
