import { SurveyTemplate } from './template.model';
import { LanguageResource } from '../interfaces/language-resource.interface';
import { AnswerOption } from './answer-option.model';

export class Survey {
  id: string;
  version: number;
  name: string;
  templateDefinition: SurveyTemplate;
  languageResources: LanguageResource[];
  answerOptions: AnswerOption[];

  constructor(json: any) {
    this.id = json.id;
    this.version = json.version;
    this.name = json.name;
    this.templateDefinition = new SurveyTemplate(json.templateDefinition);
    this.languageResources = json.languageResources;
    this.answerOptions = json.answerOptions?.map(x => new AnswerOption(x));
  }
}
