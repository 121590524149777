import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'survey-progress',
  templateUrl: './survey-progress.component.html',
  styleUrls: ['./survey-progress.component.scss']
})
export class SurveyProgressComponent implements OnInit {
  @Input() currentStep: number;
  @Input() totalSteps: number;

  constructor() {}

  ngOnInit(): void {}

  get progress() {
    return ((this.currentStep / this.totalSteps) * 100).toFixed(0) + '%';
  }
}
