import { Injectable } from '@angular/core';
import { environment } from '~environments';
import { IAppConfig } from './interfaces/config.interface';

@Injectable()
export class AppConfig {
  public static settings: IAppConfig;

  constructor() {}

  public load() {
    const jsonFile = `/assets/config/config.${environment.name}.json`;
    return new Promise<void>((resolve, reject) => {
      return fetch(jsonFile)
        .then(res => res.json())
        .then((result: IAppConfig) => {
          AppConfig.settings = result as IAppConfig;
          resolve();
        })
        .catch((response: any) => {
          reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
        });
    });
  }
}
