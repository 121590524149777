import { Component, OnInit } from '@angular/core';
import { SurveyService } from 'src/app/services/survey.service';
import { SurveyTemplate } from 'src/app/models/template.model';
import { LanguageService } from 'src/app/services/language.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-survey-completed',
  templateUrl: './survey-completed.component.html',
  styleUrls: ['./survey-completed.component.scss']
})
export class SurveyCompletedComponent implements OnInit {
  private reason: string;

  constructor(
    private surveyService: SurveyService,
    private languageService: LanguageService,
    private route: ActivatedRoute
  ) {}

  get survey() {
    return this.surveyService.currentSurvey;
  }

  ngOnInit(): void {
    this.reason = this.route.snapshot.params['reason'];
  }

  get template(): SurveyTemplate {
    return this.survey ? this.survey.templateDefinition : undefined;
  }

  get translatedFinishedSurveyTitle() {
    switch (this.languageService.getPreferredLanguage()) {
      case 'nl':
        return 'Bedankt voor uw deelname';
      case 'en':
        return 'Thank you for your participation';
    }
  }

  get translatedFinishedSurveyText() {
    switch (this.languageService.getPreferredLanguage()) {
      case 'nl':
        return this.reason === 'expired'
          ? 'De enquête is gesloten'
          : 'De enquête is met succes afgerond en verzonden';
      case 'en':
        return this.reason === 'expired'
          ? 'The questionnaire has expired'
          : 'The survey has been sent successfully ';
    }
  }
}
