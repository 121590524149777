<form [formGroup]="answerForm">
  <div class="form-group row">
    <label for="formControlRange" class="col-md-4 col-form-label">{{
      question.text | customTranslate
    }}</label>
    <div class="col-md-8 d-flex flex-row">
      <span class="font-weight-bold mr-2 my-auto">{{ min }}</span>
      <input
        type="range"
        class="form-control"
        id="formControlRange"
        [title]="answerForm.get('value').value"
        formControlName="value"
        (change)="onValueChanged($event)"
      />
      <span class="font-weight-bold ml-2 my-auto">{{ max }}</span>
    </div>
  </div>
</form>
